import React, { useState, useCallback, useContext } from 'react';
import { Segment, Message } from 'semantic-ui-react';
import { TitledBox } from './TitledBox';
import { Input } from './Input';
import { Button } from './Button';
import { I18nTranslateContext } from './I18nContext';
import { firebaseApp } from './firebase-app';
import Tabs from "./Tabs"; 


export const LoginAsRegister = ({onUsernameSubmitted}) => {

    const t = useContext(I18nTranslateContext);
    const [hasError, setHasError] = useState(false);
    const [username, setUsername] = useState('');
    const [passwordname, setPasswordname] = useState('');
    const [isToggle, setIsToggle] = useState(false);
    
  
  

    const handleUsernameChange = useCallback(
        (event) => {
          setHasError(false);
          setUsername(event.target.value);
        },
        [setHasError, setUsername]
      );
    
      const handlePasswordChange = useCallback(
        (event) => {
          setHasError(false);
          setPasswordname(event.target.value);
        },
        [setHasError, setPasswordname]
      );


      const handleSubmit = useCallback((e) => {
        e.preventDefault();
    
        if (username) {
          onUsernameSubmitted({ username, passwordname });
        } else {
          setHasError(true);
        }
      }, [username, passwordname, onUsernameSubmitted, setHasError]);


      function passwordReset(e) {
        e.preventDefault();

        firebaseApp
        .auth()
        .sendPasswordResetEmail(username);

        alert('Check your email');

      } 


      const handleClick = () => setIsToggle(value => !value);



  return (
    <>
       
          <div>
            <TitledBox title={t('username.login')}>
              <Segment basic textAlign="center">
                {hasError && (
                  <Message negative>
                    <p>{t('username.error-cant-be-empty')}</p>
                  </Message>
                )}
                <form className="pb">
                  <Input
                    placeholder={'Email'}
                    style={{ marginRight: '10px' }}
                    type={'email'}
                    autocomplete={'on'}
                    onChange={handleUsernameChange}
                  />
                  <Input
                    placeholder={'Password'}
                    style={{ marginRight: '10px' }}
                    type={'password'}
                    autocomplete={'on'}
                    onChange={handlePasswordChange}
                  />
                  <br /> <br />
                  {/* <input type="email" name="email" placeholder="Email" onChange={handleUsernameChange} />
            <input type="password" name="password" placeholder="Password" onChange={handlePasswordChange} /> */}
                  <Button primary onClick={handleSubmit}>
                    {t('username.go-btn')}
                  </Button>
                </form>
              </Segment>
            </TitledBox>
          </div>
          <Segment basic textAlign="center">
             <Button primary onClick={handleClick}>{t('username.forgotten-pass')}</Button>
          </Segment>

          <div style={{display: isToggle ? 'block': 'none'}}>
            <br/>
            <TitledBox title={t('username.forgotten-pass')}>
              <Segment basic textAlign="center">
                {hasError && (
                  <Message negative>
                    <p>{t('username.error-cant-be-empty')}</p>
                  </Message>
                )}
                <form className="pb">
                  <Input
                    placeholder={'Email'}
                    style={{ marginRight: '10px' }}
                    type={'email'}
                    autocomplete={'on'}
                    onChange={handleUsernameChange}
                  />
                  <br /> <br />
                  <Button primary onClick={passwordReset}>
                    {t('username.go-btn')}
                  </Button>
                </form>
              </Segment>
            </TitledBox>
          </div>
        
    </>
  );
};

