import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Segment, Flag } from 'semantic-ui-react';
import { I18nLanguageContext } from './I18nContext';

export const LanguageSwitcher = () => {
  const { language } = useContext(I18nLanguageContext);

{console.log(I18nLanguageContext);  }

  return (
    <div basic textAlign="center" className="lang-switcher">
      {language === 'bg' ? (
        <Link to="/en/">
          <Flag name="gb" />
        </Link>
      ) : (
        <Link to="/bg/">
          <Flag name="bg" />
        </Link>
      )}
    </div>
  );
};
