import React, { useState, useCallback, useContext } from 'react';
import { Segment, Message } from 'semantic-ui-react';
import { TitledBox } from './TitledBox';
import { Input } from './Input';
import { Button } from './Button';
import { I18nTranslateContext } from './I18nContext';
import { firebaseApp } from './firebase-app';

export const SignUp = ({onUsernameSubmitted}) => {
  const [currentUser, setCurrentUser] = useState(null);
  const t = useContext(I18nTranslateContext);
  const [hasError, setHasError] = useState(false);
  const [username, setUsername] = useState('');
  const [passwordname, serPasswordname] = useState('');



  const handleUsernameChange = useCallback(
    (event) => {
      setHasError(false);
      setUsername(event.target.value);
    },
    [setHasError, setUsername]
  );

  const handlePasswordChange = useCallback(
    (event) => {
      setHasError(false);
      serPasswordname(event.target.value);
    },
    [setHasError, serPasswordname]
  );


  

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    if (username) {
      onUsernameSubmitted({ username, passwordname });
    } else {
      setHasError(true);
    }
  }, [username, passwordname, onUsernameSubmitted, setHasError]);

  return (
    <>
      <TitledBox title={t('username.register')}>
        <Segment basic textAlign="center">
          {hasError && (
            <Message negative>
              <p>{t('username.error-cant-be-empty')}</p>
            </Message>
          )}

          <form className="pb">
          <Input
          placeholder={'Email'}
          style={{ marginRight: '10px' }}
          type={'email'}
          autocomplete={'on'}
          onChange={handleUsernameChange}
        />
             <Input
          placeholder={'Password'}
          style={{ marginRight: '10px' }}
          type={'password'}
          autocomplete={'on'}
          onChange={handlePasswordChange}
        />

            {/* <input type="email" name="email" placeholder="Email" onChange={handleUsernameChange} />
            <input type="password" name="password" placeholder="Password" onChange={handlePasswordChange} /> */}
            <br/><br/>
            <Button primary onClick={handleSubmit}>
            {t('username.go-btn')}
          </Button>
          </form> 
          
        </Segment>
      </TitledBox>
    </>
  );
};
