import React, { useContext, useEffect, useCallback } from 'react';
import { Flex } from '@chakra-ui/core';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { useHistory, useLocation } from 'react-router-dom';
import { firebaseApp } from '../firebase-app';
import { ChoseUsername } from '../ChoseUsername';
import { Logo } from '../Logo';
import { Footer } from '../Footer';
import { AuthSetStateContext } from '../AuthContext';
import Tabs from "../Tabs"; 

import { SignUp } from '../SignUp';
import { LoginAsRegister } from '../LoginAsRegister';

import { I18nTranslateContext } from '../I18nContext';

export const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const setAuthState = useContext(AuthSetStateContext);

  const t = useContext(I18nTranslateContext);

  useEffect(() => {
    return firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        const { from } = location.state || { from: { pathname: '/' } };
        history.replace(from);
      }
    });
  }, [history, location]);

  const signIn = useCallback(
    ({ username }) =>
      firebaseApp
        .auth()
        .signInAnonymously()
        .then(() => {
          setAuthState((state) => ({
            ...state,
            currentUser: {
              ...state.currentUser,
              username,
            },
          }));
          const { from } = location.state || { from: { pathname: '/' } };
          history.replace(from);
        }),
    [setAuthState, location, history]
  );

  const signInRegistered = useCallback(
    ({ username, passwordname }) =>
      firebaseApp
        .auth()
        .signInWithEmailAndPassword(username, passwordname)
        .then(() => {
          setAuthState((state) => ({
            ...state,
            currentUser: {
              ...state.currentUser,
              username,
            },
          }));
          const { from } = location.state || { from: { pathname: '/' } };
          history.replace(from);
        })
        .catch(error => {
          alert(error);
         }),
    [setAuthState, location, history]
  );


  const signUp = useCallback(
    ({ username, passwordname }) =>
      firebaseApp
        .auth()
        .createUserWithEmailAndPassword(username, passwordname)
        .then(() => {
          setAuthState((state) => ({
            ...state,
            currentUser: {
              ...state.currentUser,
              username,
            },
          }));
          const { from } = location.state || { from: { pathname: '/' } };
          history.replace(from);
        })
        .catch(error => {
          alert(error);
         }),
    [setAuthState, location, history]
  );

  return (
    <Flex flexDirection="column" alignItems="center" justifyItems="center">
      <Logo />
      <div className="game-selection">
        <Tabs>
          <div label={t('username.guest')}>
            <br/>
            <ChoseUsername onUsernameSubmitted={signIn} />
          </div>
          <div label={t('username.login')}>
          <br/>
            <LoginAsRegister onUsernameSubmitted={signInRegistered} />
          </div>
          <div label={t('username.register')}>
          <br/>
            <SignUp onUsernameSubmitted={signUp} />
          </div>
        </Tabs>
      </div>

      <LanguageSwitcher />
      {/* <Footer /> */}
    </Flex>
  );
};
