import React from 'react';
import PropTypes from 'prop-types';
import { Input as BaseInput } from 'semantic-ui-react';

export const Input = ({ placeholder, onChange, error = false, style, size, type, autocomplete } = {}) => (
  <BaseInput placeholder={placeholder} onChange={onChange} error={error} style={style} size={size} type={type} autocomplete={autocomplete} />
);

Input.propTypes = {
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.bool,
};
