import React, { useState, useCallback, useContext } from 'react';
import { Segment, Message, Form, Icon } from 'semantic-ui-react';
import { TitledBox } from './TitledBox';
import { Input } from './Input';
import { Button } from './Button';
import { I18nTranslateContext, I18nLanguageContext } from './I18nContext';
import { GameConfigurationForm } from './GameConfigurationForm';
import Tabs from "./Tabs"; 

import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';



// https://www.freecodecamp.org/news/the-firestore-tutorial-for-2020-learn-by-example/

// const games = firebase.firestore().collection('lobby-games');
// games
//   .get()
//   .then((snapshot) => {
//     const data = snapshot.docs.map((doc) => ({
//       id: doc.id,
//       ...doc.data(),
//     }));
//     console.log(12346789);

//     console.log("All data in 'books' collection", data); 
//     console.log(12346789 );
//   });



//   const gamesID = firebase
//   .firestore()
//   .collection("lobby-games")
//   .doc("status");

//   gamesID.get().then((doc) => {
//   if (!doc.exists) return;
//   console.log("Document data:", doc.data());
//   // Document data: { title: 'The Great Gatsby' }
// });


// function App() {
//   const [books, setBooks] = React.useState([]);

//   React.useEffect(() => {
// 	const unsubscribe = firebase
//       .firestore()
//       .collection("lobby-games")
//       .onSnapshot((snapshot) => {
//         const data = snapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
// 		setBooks(data);
//       });
//   }, []);
  
//   return books.map(book => <label>{`Gosho ${book}`}</label>)
// }

  






export const GameSelection = ({
  authenticatedUser,
  onCreateNewGameClicked,
  onJoinGameSubmitted,
  createNewGameLoading,
  authenticatedUserID,
}) => {
  const t = useContext(I18nTranslateContext);
  const [code, setCode] = useState('');
  const [hasError, setHasError] = useState(false);

  const handleCodeChange = useCallback(
    (event) => {
      setHasError(false);
      setCode(event.target.value);
    },
    [setHasError, setCode]
  );

  const handleJoinGameSubmitted = useCallback(() => {
    if (code === '') {
      setHasError(true);
    } else {
      onJoinGameSubmitted({ code });
    }
  }, [onJoinGameSubmitted, code]);




  const { language } = useContext(I18nLanguageContext);
  const [games, setGames] = React.useState([]);

  const [isNew, setIsNew] = useState(false);
  const [isJoin, setIsJoin] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isHowToPlay, setIsHowToPlay] = useState(false);

  React.useEffect(() => {
	const unsubscribe = firebase
      .firestore()
      .collection("lobby-games")
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
    setGames(data);
      });
  }, []);

  console.log(games);


  var found = false;
    for(var i = 0; i < games.length; i++) {
      if (games[i].status == 'WAITING_FOR_PLAYERS') {
          found = true;
          break;
      }
    }

    function logOut() {

      firebase
      .auth()
      .signOut()
      .then(() => window.location.reload());

 
    //   window.indexedDB.databases().then((r) => {
    //     for (var i = 0; i < r.length; i++) window.indexedDB.deleteDatabase(r[i].name);
    // }).then(() => {
    //     console.log('All data cleared.');
    // });

    // localStorage.removeItem("currentUser");
    // localStorage.removeItem("idToken");


    // window.location.reload();
    //window.location.reload(true);

    }








// DELETE GAMES 

  const now = Date.now();
  const gameWaiting = 10 * 60 * 1000;
  const gameStarted = 48 * 60 * 60 * 1000;


    const newDataWaiting = games.filter(function (game) {
      let timeHasPassedWaiting = now - game.createdAt;
      return timeHasPassedWaiting > gameWaiting && game.status == 'WAITING_FOR_PLAYERS' && game.createdAt != 0
    });

    const newDataStarted = games.filter(function (game) {
      let timeHasPassedStarted = now - game.createdAt;
      return timeHasPassedStarted > gameStarted && game.status == 'STARTED' && game.createdAt != 0
    });





if(newDataWaiting.length > 0) {
  newDataWaiting.forEach(function (arrayItem) {

    firebase
    .firestore()
    .collection("lobby-games")
    .doc(arrayItem.id)
    .delete()
    .then(() => console.log("Document deleted newDataWaiting")) // Document deleted
    .catch((error) => console.error("Error deleting document", error));
});
}

if(newDataStarted.length > 0) {
  newDataStarted.forEach(function (arrayItem) {
    firebase
    .firestore()
    .collection("lobby-games")
    .doc(arrayItem.id)
    .delete()
    .then(() => console.log("Document deleted newDataStarted")) // Document deleted
    .catch((error) => console.error("Error deleting document", error)); 
  });
}
 

// DELETE GAMES 


  const handleClickNew = () => setIsNew(value => !value);
  const handleClickJoin = () => setIsJoin(value => !value);
  const handleClickStarted = () => setIsStarted(value => !value);

  const handleClickHowToPlay = () => setIsHowToPlay(value => !value);


 
  


  
  
 
  return (
    <>
      <div>
        <button className="ui primary button log-out-btn" onClick={logOut}>
          {t('game-choice.log-out')}
        </button>
      </div>

      {/* <Segment basic textAlign="center">
        <p>
          {t('welcome.home')} {authenticatedUser} !
        </p>
      </Segment>
      <br /> */}
      <div className="user-name-loc">{authenticatedUser}</div>

      <div className="game-selection">
        <Tabs>
          <div label={t('game-choice.create-new')}>
            <Segment basic textAlign="center">
              <p className="how-to modla-window" style={{ display: isNew ? 'flex' : 'none' }}>
                {t('game-choice.how-new-game')}
                <br />
              </p>
              <GameConfigurationForm onSubmitted={onCreateNewGameClicked} loading={createNewGameLoading} />
            </Segment>
            <div className={'info-cont  ' + (isNew ? 'shown' : 'hidden')}>
              <Button className="info-btn" primary onClick={handleClickNew}>
                {t('game-choice.how-to')}
              </Button>
            </div>
          </div>

          <div label={t('game-choice.join')}>
            <Segment basic textAlign="center">
              <p className="how-to modla-window" style={{ display: isJoin ? 'flex' : 'none' }}>
                {t('game-choice.how-join')}
                <br />
              </p>
              <Form>
                <Form.Field inline>
                  <label className="no-m-r">{t('game-choice.enter-code')}</label>
                  <Input placeholder="code" size="mini" error={hasError} onChange={handleCodeChange} />
                </Form.Field>
              </Form>
              {hasError && (
                <Message negative>
                  <p>{t('game-choice.code-error-empty')}</p>
                </Message>
              )}
              <Button
                className="ui primary button game-to-join"
                style={{ marginTop: '0', backgroundColor: '#f6bd00', color: '#111' }}
                onClick={handleJoinGameSubmitted}
              >
                {t('game-choice.join')}
              </Button>
            </Segment>

            {/* <Segment basic textAlign="center">
            {(() => {
              if (games.filter((e) => e.status === 'WAITING_FOR_PLAYERS').length > 0) {
                return (
                  <p className="white" style={{ marginTop: '30px', marginBottom: '30px' }}>
                    {t('game-choice.or')}
                  </p>
                );
              }
            })()}
          </Segment> */}

            <Segment basic textAlign="center">
              {(() => {
                if (games.filter((e) => e.privateGame == false && e.status === 'WAITING_FOR_PLAYERS').length > 0) {
                  return <div className="white">{t('game-choice.game-to-join')}</div>;
                } else {
                  return <div className="white">{t('game-choice.no-game-to-join')}</div>;
                }
              })()}
            </Segment>

            <div className="games-to-jon">
              {(() => {
                if (authenticatedUser == 'KalKor') {
                  return games
                    .filter((game) => game.status == 'WAITING_FOR_PLAYERS')
                    .map((game) => (
                      <button className="ui primary button game-to-join">
                        <Link to={`/${language}/join/${game.id}`} style={{ color: '#fff' }}>{`${game.id}`}</Link>
                      </button>
                    ));
                } else {
                  return games
                    .filter((game) => game.privateGame == false)
                    .filter((game) => game.status == 'WAITING_FOR_PLAYERS')
                    .map((game) => (
                      <button className="ui primary button game-to-join">
                        <Link to={`/${language}/join/${game.id}`} style={{ color: '#fff' }}>{`${game.id}`}</Link>
                      </button>
                    ));
                }
              })()}
            </div>

            <div className={'info-cont ' + (isJoin ? 'shown' : 'hidden')}>
              <Button className="info-btn" primary onClick={handleClickJoin}>
                {t('game-choice.how-to')}
              </Button>
            </div>
          </div>

          <div label={t('game-choice.finish')}>
            <Segment basic textAlign="center">
              <p className="how-to modla-window" style={{ display: isStarted ? 'flex' : 'none' }}>
                {t('game-choice.how-started')}
                <br />
              </p>
              {(() => {
                if (
                  games.filter(
                    (game) => JSON.stringify(game).indexOf(authenticatedUserID) !== -1 && game.status === 'STARTED'
                  ).length > 0
                ) {
                  return <div className="white">{t('game-choice.finish-game')}</div>;
                } else {
                  return <div className="white">{t('game-choice.no-started-games')}</div>;
                }
              })()}
            </Segment>
            <div className="games-to-jon">
              {(() => {
                return games
                  .filter((game) => game.status == 'STARTED')
                  .filter((game) => JSON.stringify(game).indexOf(authenticatedUserID) !== -1)
                  .map((game) => (
                    <button className="ui primary button game-to-join">
                      <Link to={`/${language}/join/${game.id}`} style={{ color: '#fff' }}>{`${game.id}`}</Link>
                    </button>
                  ));
              })()}
            </div>
            <div className={'info-cont ' + (isStarted ? 'shown' : 'hidden')}>
              <Button className="info-btn" primary onClick={handleClickStarted}>
                {t('game-choice.how-to')}
              </Button>
            </div>
          </div>
        </Tabs>

        <Segment basic textAlign="center">
          <p className="how-to modla-window" style={{ display: isHowToPlay ? 'flex' : 'none' }}>
            {/* {t('game-choice.how-to-play-rules')} */}
            <span dangerouslySetInnerHTML={ { __html: t('game-choice.how-to-play-rules')}}></span>
            <br />
          </p>
        </Segment>
        <div className={'info-cont rules-btn ' + (isHowToPlay ? 'shown' : 'hidden')}>
          <Button className="info-btn" primary onClick={handleClickHowToPlay}>
            {t('game-choice.how-to-play')}
          </Button>
        </div>
      </div>

      {/* <TitledBox title={t('game-choice.title')}>
        <div>
          <button className="ui primary button log-out-btn" onClick={logOut}>
            {t('game-choice.log-out')}
          </button>
        </div>

        <Segment basic textAlign="center">
          <p>
            {t('welcome.home')} {authenticatedUser} !
          </p>
        </Segment>
        <Segment basic textAlign="center">
          <GameConfigurationForm onSubmitted={onCreateNewGameClicked} loading={createNewGameLoading} />
          <p style={{ marginTop: '30px', marginBottom: '30px' }}>{t('game-choice.or')}</p>
          <Form>
            <Form.Field inline>
              <label className="no-m-r">{t('game-choice.join-game')}</label>
              <Input placeholder="code" size="mini" error={hasError} onChange={handleCodeChange} />
            </Form.Field>
          </Form>
          {hasError && (
            <Message negative>
              <p>{t('game-choice.code-error-empty')}</p>
            </Message>
          )}
          <Button style={{ marginTop: '10px' }} onClick={handleJoinGameSubmitted}>
            {t('game-choice.join')}
          </Button>
    

          <br />
          <br />
          <br />
          <br />

          <div>
            {(() => {
              if (
                games.filter((e) => e.status === 'STARTED').length > 0 &&
                games.filter((game) => JSON.stringify(game).indexOf(authenticatedUserID) !== -1).length > 0
              ) {
                return <div>{t('game-choice.finish-game')}</div>;
              }
            })()}
          </div>
          <div className="games-to-jon">
            {(() => {
              return games
                .filter((game) => game.status == 'STARTED')
                .filter((game) => JSON.stringify(game).indexOf(authenticatedUserID) !== -1)
                .map((game) => (
                  <button className="ui primary button game-to-join">
                    <Link to={`/${language}/join/${game.id}`} style={{ color: '#fff' }}>{`${game.id}`}</Link>
                  </button>
                ));
            })()}
          </div>
          <br />
          <br />

          <div>
            {(() => {
              if (games.filter((e) => e.status === 'WAITING_FOR_PLAYERS').length > 0) {
                return <div>{t('game-choice.game-to-join')}</div>;
              }
            })()}
          </div>

          <div className="games-to-jon">
            {(() => {
              if (authenticatedUser == 'KalKor') {
                return games
                  .filter((game) => game.status == 'WAITING_FOR_PLAYERS')
                  .map((game) => (
                    <button className="ui primary button game-to-join">
                      <Link to={`/${language}/join/${game.id}`} style={{ color: '#fff' }}>{`${game.id}`}</Link>
                    </button>
                  ));
              } else {
                return games
                  .filter((game) => game.privateGame == false)
                  .filter((game) => game.status == 'WAITING_FOR_PLAYERS')
                  .map((game) => (
                    <button className="ui primary button game-to-join">
                      <Link to={`/${language}/join/${game.id}`} style={{ color: '#fff' }}>{`${game.id}`}</Link>
                    </button>
                  ));
              }
            })()}
          </div>
        </Segment>
      </TitledBox> */}
    </>
  );
};

