import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { TitledBox } from './TitledBox';
import { Avatar } from './Avatar';
import { Button } from './Button';
import { I18nTranslateContext } from './I18nContext';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { useHistory } from 'react-router-dom';

const MIN_PLAYERS_TO_START_GAME = 3;

const canStartGame = (players) => players.length >= MIN_PLAYERS_TO_START_GAME;

export const GameWaitingForPlayers = ({ gameId, players, isHost, onStartGameClicked, startGameIsLoading }) => {
  const t = useContext(I18nTranslateContext);
  const history = useHistory();
  

  function deleteGame() {
    firebase
    .firestore()
    .collection("lobby-games")
    .doc(`${gameId}`)
    .delete()
    .then(() => console.log("Document deleted")) // Document deleted
    .catch((error) => console.error("Error deleting document", error));

    history.push("/");
  }

  function share() {
      if (navigator.share) {
        navigator.share({
            title: 'Web Share API Draft',
            text: 'Please join the game!',
            url: `https://dixit.koravski.com/en/game/${gameId}`,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        console.log('Share not supported on this browser, do it the old way.');
      }
    

  }
  return (
    <TitledBox title={`${t('game-waiting.party')} ${gameId}`}>
      <Segment textAlign="center" basic style={{ padding: '0' }}>
        <p>
          {canStartGame(players)
            ? isHost
              ? t('game-waiting.can-be-started')
              : t('game-waiting.waiting-to-be-started')
            : t('game-waiting.waiting-for-players')}
        </p>
      </Segment>
      {players.map(({ id, username }) => (
        <Segment basic key={id} style={{ padding: '0', paddingLeft: '20px' }}>
          <Avatar username={username} showUsername={true} />
        </Segment>
      ))}
      {canStartGame(players) && isHost && (
        <Segment textAlign="center" basic>
          <Button primary onClick={onStartGameClicked} loading={startGameIsLoading}>
            {t('game-waiting.start-game')}
          </Button>
        </Segment>
      )}
       <br/><br/><br/>
       <Segment textAlign="center" basic>
       <button onClick={share} className="ui primary button share-btn-new">{t('game-waiting.share')}</button>
       </Segment>
       
      <br/><br/><br/>
      { isHost && (
          <button className="ui primary button delete-this-game" style={{ background: '#ac0901', color: '#fff'}} onClick={deleteGame} >{t('game-waiting.delete-the-game')}</button>
      )}
    </TitledBox>
  );
};

GameWaitingForPlayers.propTypes = {
  gameId: PropTypes.string.isRequired,
  players: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    })
  ),
};
