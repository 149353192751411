import React, { useState, useCallback } from 'react';
import { I18nTranslateContext, I18nLanguageContext } from './I18nContext';

const catalog = new Map([
  [
    'welcome.home',
    new Map([
      ['bg', 'Привет'],
      ['en', 'Welcome'],
    ]),
  ],
  [
    'game-configuration.ending-condition',
    new Map([
      ['bg', 'Начин за приключване на играта'],
      ['en', 'End of game condition'],
    ]),
  ],
  [
    'game-configuration.value-label',
    new Map([
      ['bg', 'Брой'],
      ['en', 'Value'],
    ]),
  ],
  [
    'game-configuration.default',
    new Map([
      ['bg', 'По подразбиране'],
      ['en', 'Default'],
    ]),
  ],
  [
    'game-configuration.x-times-storyteller',
    new Map([
      ['bg', 'Колко пъти да си разказвач'],
      ['en', 'Number of times being storyteller'],
    ]),
  ],
  [
    'game-configuration.points-limit',
    new Map([
      ['bg', 'Брой точки'],
      ['en', 'Points limit'],
    ]),
  ],
  [
    'game-configuration.value-error',
    new Map([
      ['bg', 'Числото трябва да е полоително'],
      ['en', 'The value must be superior to zero'],
    ]),
  ],
  [
    'game-choice.title',
    new Map([
      ['bg', 'Настройки'],
      ['en', 'Game choice'],
    ]),
  ],
  [
    'game-choice.create-new-game',
    new Map([
      ['bg', 'нова игра'],
      ['en', 'Create new game'],
    ]),
  ],
  [
    'game-choice.how-to',
    new Map([
      ['bg', 'инфо'],
      ['en', 'info'],
    ]),
  ],
  [
    'game-choice.create-new',
    new Map([
      ['bg', 'нова'],
      ['en', 'new'],
    ]),
  ],

  [
    'game-choice.no-started-games',
    new Map([
      ['bg', 'Нямаш недовършени игри'],
      ['en', 'No started games'],
    ]),
  ],
  [
    'game-choice.no-game-to-join',
    new Map([
      ['bg', 'Няма публични игри в момента'],
      ['en', 'No public games to join'],
    ]),
  ],


  [
    'game-choice.or',
    new Map([
      ['bg', 'или'],
      ['en', 'or'],
    ]),
  ],
  [
    'game-choice.join-game',
    new Map([
      ['bg', 'Присъедини се към игра:'],
      ['en', 'Join a game:'],
    ]),
  ],
  [
    'game-choice.enter-code',
    new Map([
      ['bg', 'Въведи код'],
      ['en', 'Eneter code'],
    ]),
  ],

  [
    'game-choice.join',
    new Map([
      ['bg', 'присъедини'],
      ['en', 'join'],
    ]),
  ],

  [
    'game-choice.code-error-empty',
    new Map([
      ['bg', 'Въведете код'],
      ['en', 'The code cannot be empty'],
    ]),
  ],
  [
    'game-choice.join',
    new Map([
      ['bg', 'присъединяване'],
      ['en', 'join'],
    ]),
  ],
  [
    'game-choice.discord',
    new Map([
      ['bg', 'Trouvez des parties en attente de joueurs grâce à Discord !'],
      ['en', 'Find other players on Discord !'],
    ]),
  ],
  [
    'game-choice.how-new-game',
    new Map([
      ['bg', 'В менюто "нова" може да създадете нова игра. Частните игри няма да се появят при публичнте игри в менюто "присъединяване".'],
      ['en', 'In "new" you can create a new game. Private games will not appear in public games in "join" menu.'],
    ]),
  ],
  [
    'game-choice.how-join',
    new Map([
      ['bg', 'В менюто "присъединяване" са публичните игри към които може да се присъедините. Също така може да въведете код от приятел и да се присъединете към неговата игра.'],
      ['en', 'In "join" you will find the public games you can join. You can also enter a code from a friend and join his game.'],
    ]),
  ],
  [
    'game-choice.how-started',
    new Map([
      ['bg', 'В менюто "започнати" са игрите, които сте започнали, но поради някаква причина сте излезли от играта. Започнатите игри се пазят 48 часа на сървъра. Предостатъчно време, за да я завършите.'],
      ['en', 'In "started" you will find the games you have started, but for some reason you left the game. Started games are stored on the server for 48 hours. Enough time to finish it.'],
    ]),
  ],
  [
    'game-choice.how-to-play-rules',
    new Map([
      ['bg', `<div><p>Един играч е разказвачът на хода и разглежда изображенията на 7-те карти в ръката си. От едно от тях се съставя изречение и го казва на глас (без да показва картата на останалите играчи).</p>
      <p>Всеки играч избира карта от ръката си, която най-добре съответства на изречението, и дава избраната карта на разказвача, без да я показва на останалите.</p>
      <p>Разказвачът разбърква картата си с всички получени карти. Всички карти се показват с лицето нагоре и всеки играч трябва да заложи коя карта е на разказвача.</p>
      <p>Ако <span class="yellow">никой</span> или <span class="yellow">всички</span> намерят правилната карта, разказвачът отбелязва <span class="yellow">0</span>, а всеки от <span class="yellow">останалите</span> играчи отбелязва <span class="yellow">2</span> точки. В противен случай <span class="yellow">разказвачът</span> и този, който е намерил правилната карта, печелят по <span class="yellow">3</span> точки. Играчите взимат по <span class="yellow">1</span> точка за всеки глас за собствената си карта.</p>
      <p>Играчът с най-много точки печели играта.</p></div>`],

      ['en', `<div><p>One player is the storyteller for the turn and looks at the images on the 7 cards in his hand. From one of these, he makes up a sentence and says it out loud (without showing the card to the other players).</p>
      <p>Each other player selects the card in their hands which best matches the sentence and gives the selected card to the storyteller, without showing it to the others.</p>
      <p>The storyteller shuffles his card with all the received cards. All pictures are shown face up and every player has to bet upon which picture was the storyteller's.</p>
      <p>If <span class="yellow">nobody</span> or <span class="yellow">everybody</span> finds the correct card, the storyteller scores <span class="yellow">0</span>, and each of the <span class="yellow">other</span> players scores <span class="yellow">2</span>. Otherwise the <span class="yellow">storyteller</span> and whoever found the correct answer score <span class="yellow">3</span>. Players score <span class="yellow">1</span> point for every vote for their own card.</p>
      <p>The player with the most points wins the game.</p></div>`],
    ]),
  ],
  [
    'game-choice.how-to-play',
    new Map([
      ['bg', 'правила'],
      ['en', 'rules'],
    ]),
  ],
  [
    'footer.rights',
    new Map([
      ['bg', 'Dixit - вички права са запазени '],
      ['en', 'Dixit - All rights reserved '],
    ]),
  ],
  [
    'footer.game-created-by',
    new Map([
      ['bg', 'Jeu original créé par Jean-Louis Roubira, illustré par Marie Cardouat.'],
      ['en', 'Original game created by Jean-Louis Roubira, illustrations by Marie Cardouat.'],
    ]),
  ],
  [
    'footer.created-by',
    new Map([
      ['bg', 'Créé par'],
      ['en', 'Created by'],
    ]),
  ],
  [
    'game.cant-start-game',
    new Map([
      ['bg', 'Не може да почне играта :('],
      ['en', "Can' start the game :("],
    ]),
  ],
  [
    'game.first-win',
    new Map([
      ['bg', 'За да спечелите, изкарайте '],
      ['en', "To win score "],
    ]),
  ],
  [
    'game.loading',
    new Map([
      ['bg', 'Зареждане...'],
      ['en', 'Loading...'],
    ]),
  ],
  [
    'an-error-has-occured',
    new Map([
      ['bg', 'Грешка :('],
      ['en', 'An error has occured :('],
    ]),
  ],
  [
    'refresh-page',
    new Map([
      ['bg', 'Рефрешнете страницата'],
      ['en', 'Try to refresh the page'],
    ]),
  ],
  [
    'game.last-turn',
    new Map([
      ['bg', 'Последен ход!'],
      ['en', 'Last turn !'],
    ]),
  ],
  [
    'game.remaining-turns',
    new Map([
      ['bg', 'Оставащи ходове : '],
      ['en', 'Remaining turns : '],
    ]),
  ],
  [
    'error.oops',
    new Map([
      ['bg', 'Оппс...'],
      ['en', 'Oops...'],
    ]),
  ],
  [
    'error.punish-me',
    new Map([
      ['bg', 'Програмиста трябва да го накажете...'],
      ['en', 'The developer has to be punished...'],
    ]),
  ],
  [
    'game.does-not-exist',
    new Map([
      ['bg', "Не е намерана игра с този код!"],
      ['en', 'No game found for this code'],
    ]),
  ],
  [
    'game.error-not-in-game',
    new Map([
      ['bg', "Не си в тази игра"],
      ['en', "You're not in that game"],
    ]),
  ],
  [
    'game.error-game-full',
    new Map([
      ['bg', 'Играта е пълна'],
      ['en', 'This game is full'],
    ]),
  ],
  [
    'game.accessing-game',
    new Map([
      ['bg', 'Играта е пълна...'],
      ['en', 'This game is full'],
    ]),
  ],
  [
    'game.click-if-not-redirected',
    new Map([
      ['bg', "Кликнете тук."],
      ['en', "Click here if you're not redirected"],
    ]),
  ],
  [
    'game.refresh-page',
    new Map([
      ['bg', "Рефрешни страницата"],
      ['en', "Refresh the page"],
    ]),
  ],
  [
    'game.go-home',
    new Map([
      ['bg', "Върнете се в началото"],
      ['en', "Go back to Home"],
    ]),
  ],
  [
    'turn.waiting-for-other-players',
    new Map([
      ['bg', 'В очакване на други играчи...'],
      ['en', 'Waiting for other players...'],
    ]),
  ],
  [
    'turn.you-are-the-storyteller',
    new Map([
      ['bg', 'Ти си РАЗКАЗВАЧЪТ!'],
      ['en', 'You are the storyteller'],
    ]),
  ],
  [
    'turn.waiting-for-storyteller',
    new Map([
      ['bg', 'В очакване на разказвача...'],
      ['en', 'Waiting for the storyteller...'],
    ]),
  ],
  [
    'turn.other-players-voting',
    new Map([
      ['bg', 'Другите играчи гласуват...'],
      ['en', 'Other players are voting...'],
    ]),
  ],
  [
    'turn.error-cant-vote-for-own-card',
    new Map([
      ['bg', 'Не може да гласуваш за своята карта !'],
      ['en', "You can't vote for your own card"],
    ]),
  ],
  [
    'turn.cards-exposed-to-vote',
    new Map([
      ['bg', 'Изложени карти'],
      ['en', 'Exposed cards'],
    ]),
  ],
  [
    'card.owner',
    new Map([
      ['bg', 'Карта на {$}'],
      ['en', "{$}'s card"],
    ]),
  ],
  [
    'card-modal.clue',
    new Map([
      ['bg', "Дайта указание за картата"],
      ['en', 'Define a clue for this card'],
    ]),
  ],
  [
    'card-modal.clue-placeholder',
    new Map([
      ['bg', 'указание'],
      ['en', 'clue'],
    ]),
  ],
  [
    'card-modal.validate',
    new Map([
      ['bg', 'Давай'],
      ['en', 'Go'],
    ]),
  ],
  [
    'card-modal.chose-card',
    new Map([
      ['bg', 'ИЗБЕРЕТЕ ТАЗИ КАРТА'],
      ['en', 'CHOSE THIS CARD'],
    ]),
  ],
  [
    'card-modal.vote-card',
    new Map([
      ['bg', 'ГЛАСУВАЙТЕ ЗА ТАЗИ КАРТА'],
      ['en', 'VOTE FOR THIS CARD'],
    ]),
  ],
  [
    'card-modal.vote-result',
    new Map([
      ['bg', 'Играчи, гласували за тази карта :'],
      ['en', 'Players having voted for this card :'],
    ]),
  ],
  [
    'card-modal.no-votes',
    new Map([
      ['bg', "Никой не е гласувал за тази карта."],
      ['en', 'Nobody has voted for this card.'],
    ]),
  ],
  [
    'username.chose-username',
    new Map([
      ['bg', 'Избери си име'],
      ['en', 'Choose a username'],
    ]),
  ],
  [
    'username.guest',
    new Map([
      ['bg', 'Гост'],
      ['en', 'Guest'],
    ]),
  ],
  [
    'username.forgotten-pass',
    new Map([
      ['bg', 'Забравена парола'],
      ['en', 'Lost password'],
    ]),
  ],
  [
    'username.register',
    new Map([
      ['bg', 'Регистрирай се'],
      ['en', 'Sign up'],
    ]),
  ],
  [
    'username.login',
    new Map([
      ['bg', 'Влез'],
      ['en', 'Log in'],
    ]),
  ],
  [
    'username.go-btn',
    new Map([
      ['bg', 'Давай'],
      ['en', 'Submit'],
    ]),
  ],
  [
    'username.placeholder',
    new Map([
      ['bg', 'потребителско име'],
      ['en', 'username'],
    ]),
  ],
  [
    'username.error-cant-be-empty',
    new Map([
      ['bg', 'Потребителското име не може да бъде празно'],
      ['en', "The username can't be empty"],
    ]),
  ],
  [
    'game-waiting.can-be-started',
    new Map([
      ['bg', 'Потребителското име не може да бъде празно'],
      ['en', "The username can't be empty"],
    ]),
  ],
  [
    'game-waiting.waiting-to-be-started',
    new Map([
      ['bg', 'Изчаква се играта да започне'],
      ['en', 'Waiting for the game to be started'],
    ]),
  ],
  [
    'game-waiting.waiting-for-players',
    new Map([
      ['bg', 'В очакване на други играчи ... (3 играчи мин.)'],
      ['en', 'Waiting for other players... (3 players min)'],
    ]),
  ],
  [
    'game-waiting.start-game',
    new Map([
      ['bg', 'Започни играта !'],
      ['en', 'Start the game !'],
    ]),
  ],
  [
    'game-ended.ranking.first',
    new Map([
      ['bg', 'първи'],
      ['en', '1st'],
    ]),
  ],
  [
    'game-ended.ranking.other',
    new Map([
      ['bg', '{$}'],
      ['en', '{$}'],
    ]),
  ],
  [
    'clue.being-the-storyteller',
    new Map([
      ['bg', "Дадохте следната указание : "],
      ['en', 'You gave the following clue : '],
    ]),
  ],
  [
    'clue.being-a-player',
    new Map([
      ['bg', "{$} даде следното указание : "],
      ['en', '{$} gave the following clue : '],
    ]),
  ],
  [
    'titled-card-grid.your-hand',
    new Map([
      ['bg', 'Твоите карти'],
      ['en', 'Your hand'],
    ]),
  ],
  [
    'titled-card-grid.chose-card',
    new Map([
      ['bg', 'Избери карта'],
      ['en', 'Chose a card'],
    ]),
  ],
  [
    'titled-card-grid.chose-two-cards',
    new Map([
      ['bg', 'Избери две карти'],
      ['en', 'Chose two cards'],
    ]),
  ],
  [
    'titled-card-grid.find-card',
    new Map([
      ['bg', 'Намери картата на {$}'],
      ['en', "Find {$}'s card"],
    ]),
  ],
  [
    'titled-card-grid.vote-results',
    new Map([
      ['bg', 'Резултат след гласуването'],
      ['en', 'Vote results'],
    ]),
  ],
  [
    'titled-card-grid.see-leaderboard',
    new Map([
      ['bg', 'Виж класацията !'],
      ['en', 'See the leaderboard !'],
    ]),
  ],
  [
    'titled-card-grid.go-to-next-turn',
    new Map([
      ['bg', 'Следващ ход'],
      ['en', 'Go to next turn'],
    ]),
  ],
  [
    'endgame.winner',
    new Map([
      ['bg', 'ПОБЕДИТЕЛЯТ Е'],
      ['en', 'And the winner is'],
    ]),
  ],
  [
    'endgame.result',
    new Map([
      ['bg', 'Резултат'],
      ['en', 'Result'],
    ]),
  ],
  [
    'endgame.points',
    new Map([
      ['bg', 'точки'],
      ['en', 'points'],
    ]),
  ],  
  [
    'game-choice.game-to-join',
    new Map([
      ['bg', 'Присъедини се към:'],
      ['en', 'Games to join:'],
    ]),
  ],
  [
    'game-choice.finish-game',
    new Map([
      ['bg', 'Довърши играта:'],
      ['en', 'Finish the game:'],
    ]),
  ],
  [
    'game-choice.finish',
    new Map([
      ['bg', 'започнати'],
      ['en', 'started'],
    ]),
  ],
  [
    'game-waiting.party',
    new Map([
      ['bg', 'Парти: '],
      ['en', 'Party: '],
    ]),
  ],
  [
    'game-waiting.delete-the-game',
    new Map([
      ['bg', 'Изтрий играта'],
      ['en', 'Delete game'],
    ]),
  ],
  [
    'game-waiting.share',
    new Map([
      ['bg', 'Сподели'],
      ['en', 'Share'],
    ]),
  ],
  [
    'game-choice.log-out',
    new Map([
      ['bg', 'Излез'],
      ['en', 'Log out'],
    ]),
  ],
  [
    'game-configuration.private',
    new Map([
      ['bg', 'Частна'],
      ['en', 'Private'],
    ]),
  ],
  
]);

export const I18nProvider = ({ children }) => {
  const [language, setLanguage] = useState('bg');
  const t = useCallback(
    (name, param = '') => {
      if (!catalog.has(name)) {
        console.error(`${name} not found in catalog`);
        return name;
      }
      if (!catalog.get(name).has(language)) {
        console.error(`${language} not found in catalog for ${name}`);
        return name;
      }
      return catalog.get(name).get(language).replace('{$}', param);
    },
    [language]
  );

  return (
    <I18nTranslateContext.Provider value={t}>
      <I18nLanguageContext.Provider value={{ language, setLanguage }}>{children}</I18nLanguageContext.Provider>
    </I18nTranslateContext.Provider>
  );
};
