import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import { LanguageSwitcher } from './LanguageSwitcher';
import './css/main.css';

export const Logo = () => {
  return (
    <header>
        <div className="logo-txt">
        <Link to="/">
            Dixit
         </Link>
        </div>
      {/* <LanguageSwitcher /> */}

    </header>
    // <Link to="/">
    //   <Image src="/dixit.png" centered style={{ padding: '20px' }} alt="Dixit Online" />
    // </Link>
  );
};
