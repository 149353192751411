import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Segment, Icon } from 'semantic-ui-react';
import { TitledBox } from './TitledBox';
import { Avatar } from './Avatar';
import { Flex } from '@chakra-ui/core';
import { I18nTranslateContext } from './I18nContext';

const getIconForNonWinner = (rank) => {
  switch (rank) {
    case 1:
      return <Icon color="grey" name="certificate" size="big" />;
    case 2:
      return <Icon color="brown" name="certificate" size="large" />;
    default:
      return <Icon name="certificate" />;
  }
};

export const GameEnded = ({ players }) => {
  const t = useContext(I18nTranslateContext);
  players.sort(({ score: scoreA }, { score: scoreB }) => scoreB - scoreA);
  return (
    <TitledBox title={t('endgame.result')}>
      <Segment textAlign="center" basic style={{ padding: '0', paddingLeft: '0px'}}>
        <p>{t('endgame.winner')}</p>
      </Segment>
      {players.map(({ score, username }, index) => (
        <Segment basic key={index} style={{ padding: '0' }}>
          <Flex justifyContent={index === 0 ? 'center' : 'flex-start'} alignItems="center">
          {/* <Avatar username={username} showUsername={true} /> */}
            {index === 0 && (
              <div className="winner-cont" style={{ marginBottom: '20px', marginTop: '20px' }}>
                <div className="avatar-winner">
                <Avatar username={username} showUsername={true} />
                </div>
                <Icon color="yellow" name="trophy" size="huge" className="two"/><br/>
                <strong style={{ size: '2rem' }} className="three"> 
                  {/* {t('game-ended.ranking.first')} -  */}
                  {score} {t('endgame.points')}
                </strong>
              </div>
            )}
            {index !== 0 && (
              <span className="player-container">
                <strong className="player-container__single">
                  {/* {getIconForNonWinner(index)} */}
                  <span className="oredering">{t('game-ended.ranking.other', index + 1)} </span>
                  <div className="avatar-all">
                    <Avatar username={username} showUsername={true}/>
                  </div>
                  <span>{score} {t('endgame.points')}</span>
                </strong>
              </span>
            )}
          </Flex>
        </Segment>
      ))}
    </TitledBox>
  );
};

GameEnded.propTypes = {
  players: PropTypes.arrayOf(
    PropTypes.shape({
      score: PropTypes.number.isRequired,
      username: PropTypes.string.isRequired,
    })
  ),
};
