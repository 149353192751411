import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

const config = {
  apiKey: 'AIzaSyBT6LIntbd7i_HTTqExgDjWuEWWvE8ekd8',
  authDomain: 'dixit-16935.firebaseapp.com',
  databaseURL: 'https://dixit-16935-default-rtdb.firebaseio.com',
  projectId: 'dixit-16935',
  storageBucket: 'dixit-16935.appspot.com',
  messagingSenderId: '438742225854',
  appId: '1:438742225854:web:b7b7eae9a49189b4cf5fef',
  measurementId: 'G-S98RCRWZCM',
};
const firebaseDefaultApp = firebase.initializeApp(config);

export const firebaseApp = firebaseDefaultApp;
